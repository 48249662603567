'use client';

import {
  useEffect,
  useState,
  createContext,
  useContext,
  type ReactNode,
} from 'react';
import { usePathname } from 'next/navigation';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from '~/shadcn/ui/dialog';
import { Button } from '~/shadcn/ui/button';

const AnalyticsContext = createContext({
  showCookiePopup: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  acceptCookiePolicy: () => {},
});

interface LocationProviderProps {
  children: ReactNode;
}

const CookiePolicyProvider: React.FC<LocationProviderProps> = ({
  children,
}) => {
  const pathname = usePathname();
  const [showCookiePopup, setShowCookiePopup] = useState(false);

  const acceptCookiePolicy = () => {
    localStorage.setItem('cookiePolicyAccepted', 'true');
    setShowCookiePopup(false);
  };

  useEffect(() => {
    const isCookiePolicyAccepted =
      localStorage.getItem('cookiePolicyAccepted') === 'true';
    if (!isCookiePolicyAccepted) {
      setShowCookiePopup(true);
    }
  }, [pathname]);

  return (
    <AnalyticsContext.Provider value={{ showCookiePopup, acceptCookiePolicy }}>
      {children}
      {showCookiePopup && (
        <CookiePolicyPopup
          open={showCookiePopup}
          onAccept={acceptCookiePolicy}
        />
      )}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

const CookiePolicyPopup = ({
  onAccept,
  open,
}: {
  open: boolean;
  onAccept: () => void;
}) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cookie Policy</DialogTitle>
          <DialogDescription>
            Vi använder cookies för att göra din upplevelse smidig och säker. En
            del cookies används för att webbplatsen ska fungera eller
            förbättras, andra för att skräddarsy din upplevelse.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button onClick={onAccept} variant="default">
            Godkänn
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CookiePolicyProvider;
